import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"amp-module-page"},[_c('amp-row-first',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.video.id && !_vm.isEditable)?_c('status-chip',{staticClass:"mb-3",class:{
                    'amp-module-element-hoisted':
                        _vm.$vuetify.breakpoint.mdAndUp
                },attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp,"status":_vm.status}}):_vm._e(),(_vm.isBusy)?_c(VSkeletonLoader,{staticClass:"pt-2 pb-1",attrs:{"loading":"","type":"heading"}}):_c('h2',{staticClass:"font-weight-medium text-justify"},[_vm._v(" "+_vm._s(_vm.video.title || ' ')+" ")]),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),(_vm.isBusy)?_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(VContainer,[_c(VRow,{staticClass:"my-12"},[_c(VCol,{staticClass:"my-12 py-12 text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)]},proxy:true}],null,false,3908201424)}):(_vm.hasPreview)?_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('label',{staticClass:"d-block mb-2"},[_vm._v("Low Resolution Preview")]),_c('video',{staticClass:"position--relative",class:{
                    'portrait-video': _vm.isPortrait
                },attrs:{"width":"100%","controls":"","loop":"","playsinline":"","src":_vm.previewSrc}},[_vm._v(" Your browser does not support the video element. Please update your browser version. ")]),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}):_vm._e(),(_vm.needsGeneration && !_vm.isBusy)?_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('video-preview-generator',{attrs:{"video-id":_vm.moduleId},on:{"generated":_vm.load,"error":_vm.edit}}),_c(VDivider,{staticClass:"my-4"})]},proxy:true}],null,false,3569110636)}):_vm._e(),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.isBusy)?_c(VSkeletonLoader,{staticClass:"mx-n4",attrs:{"loading":"","type":"list-item-three-line"}}):_c('h3',{staticClass:"font-weight-regular text-justify",domProps:{"innerHTML":_vm._s(_vm.video.description || '&#160;')}}),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),_c('amp-row-last',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._l((_vm.video.tags_array),function(tag){return _c(VChip,{key:tag,staticClass:"mr-2 mb-2",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(tag)+" ")])}),(_vm.isDownloadable)?_c(VDivider,{staticClass:"mt-2 mb-4"}):_vm._e(),(_vm.isDownloadable)?_c(VBtn,{staticClass:"float-end mb-4",attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"loading":_vm.isDownloading},on:{"click":_vm.download}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("download")]),_vm._v(" Download High Resolution Video ")],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }