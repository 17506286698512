<template>
    <v-container class="amp-module-page">
        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="video.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />
                <v-skeleton-loader
                    v-if="isBusy"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />
                <h2 v-else class="font-weight-medium text-justify">
                    {{ video.title || '&#160;' }}
                </h2>
                <v-divider class="my-4" />
            </template>
        </amp-row-first>
        <amp-row v-if="isBusy">
            <template #input>
                <v-container>
                    <v-row class="my-12">
                        <v-col class="my-12 py-12 text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </amp-row>
        <amp-row v-else-if="hasPreview">
            <template #input>
                <label class="d-block mb-2">Low Resolution Preview</label>
                <video
                    class="position--relative"
                    :class="{
                        'portrait-video': isPortrait
                    }"
                    width="100%"
                    controls
                    loop
                    playsinline
                    :src="previewSrc"
                >
                    Your browser does not support the video element. Please
                    update your browser version.
                </video>
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row v-if="needsGeneration && !isBusy">
            <template #input>
                <video-preview-generator
                    :video-id="moduleId"
                    @generated="load"
                    @error="edit"
                />
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isBusy"
                    loading
                    type="list-item-three-line"
                    class="mx-n4"
                />
                <!--  eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="video.description || '&#160;'"
                ></h3>
                <!--  eslint-enable vue/no-v-html -->
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row-last>
            <template #input>
                <v-chip
                    v-for="tag in video.tags_array"
                    :key="tag"
                    color="secondary"
                    class="mr-2 mb-2"
                >
                    {{ tag }}
                </v-chip>
                <v-divider v-if="isDownloadable" class="mt-2 mb-4" />
                <v-btn
                    v-if="isDownloadable"
                    class="float-end mb-4"
                    :block="$vuetify.breakpoint.smAndDown"
                    :loading="isDownloading"
                    @click="download"
                >
                    <v-icon small left>download</v-icon>
                    Download High Resolution Video
                </v-btn>
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { parse } from 'content-disposition';

import { Endpoint, MediaModuleEndpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { AudioPlayerChip } from '@/components/AudioPlayerChip';
import { StatusChip } from '@/components/StatusChip';
import { ACombobox } from '@/components/AForm/Inputs/ACombobox';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';

import { ASlideshow } from '@/components/ASlideshow';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import VideoPreviewGenerator from './VideoPreviewGenerator.vue';

import type { AmpModules } from '@/types/Announcement';
import type { VideoPr } from '@/types/Video';
import type { ModuleLink } from '@/types/ModuleLink';
import type { NavigationGuardNext, Route } from 'vue-router';

Component.registerHooks(['beforeRouteEnter']);

@Component({
    components: {
        AudioPlayerChip,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        AForm,
        ATextInput,
        ASelectInput,
        ACombobox,
        ASlideshow,
        StatusChip,
        VideoPreviewGenerator
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class Video extends mixins(Endpoint, MediaModuleEndpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    video: Partial<VideoPr> = {
        id: 0,
        preview_available: false,
        is_editable: false,
        status_string: '',
        downloadable: false
    };

    endpoint = '/video_prs/review';

    isDownloading = false;

    hasReview = false;

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: false //this.isClient
            }
        ];
    }

    get moduleId() {
        return this.isPortrait
            ? this.modules?.portrait_video_pr_id
            : this.modules?.video_pr_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return [
            `/announcements/edit/${this.announcementId}/video`,
            this.isPortrait && 'portrait'
        ]
            .filter(Boolean)
            .join('/');
    }

    get hasPreview() {
        return this.video.preview_available;
    }

    get previewSrc() {
        return `/video_prs/generate_preview/${this.video.id}?get_preview=1&_=${this.cacheBustingTag}`;
    }

    get cacheBustingTag() {
        return +new Date();
    }

    get isEditable() {
        return this.video.is_editable;
    }

    get status() {
        return this.video.status_string ?? '';
    }

    get isDownloadable() {
        return this.video.downloadable;
    }

    get downloadLink() {
        return `/video_prs/download/${this.video.id}?time=${this.cacheBustingTag}`;
    }

    get isPortrait() {
        return this.$route.params.mode === 'portrait';
    }

    get needsGeneration() {
        return Boolean(this.video.id && !this.hasPreview);
    }

    get isBusy() {
        return this.isLoading || this.isUpdating;
    }

    beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Video>) {
        return next(that => {
            that.onMounted();
        });
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        } else {
            this.edit();
        }
    }

    onData(data: { video: VideoPr }) {
        this.video = data.video;

        this.emitLinks();
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }

    edit() {
        this.$router.push(this.editLink);
    }

    async download() {
        this.setDownloading();

        try {
            const file = await fetch(this.downloadLink);

            if (file) {
                const blob = await file.blob();

                const href = URL.createObjectURL(blob);

                const { parameters } = parse(
                    file.headers.get('content-disposition') || ''
                );

                const filename =
                    parameters?.filename || `Video_${this.moduleId}.mp4`;

                Object.assign(
                    document.createElementNS(
                        'http://www.w3.org/1999/xhtml',
                        'a'
                    ),
                    {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        download: filename,
                        href
                    }
                ).click();

                setTimeout(function () {
                    URL.revokeObjectURL(href);
                }, 4e4); // 40s
            }
        } catch {
            this.$store.dispatch(
                'notification/error',
                'Unable to download video. Try again later or contact support.'
            );
        }

        this.setDownloading(false);
    }

    setDownloading(isDownloading = true) {
        this.isDownloading = isDownloading;

        this.$store.dispatch(isDownloading ? 'loading/show' : 'loading/hide');
    }
}
</script>

<style lang="scss" scoped>
.portrait-video {
    max-height: 800px;
    max-width: 450px;
}
</style>
